import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ThemeContext from "../context/ThemeContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPhoneSquare,
  faSquareEnvelope,
} from "@fortawesome/free-solid-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"

const PolicyPage = () => {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <Layout>
          <Seo title="Techimpace Best Digital Agency for Website Designing, Web Development and Software Development" />
          <section className={theme.dark ? " dark dark-5 " : " light "}>
            <div className="container">
              <div className="row">
                <div className="row">
                  <div className="col-12">
                    <h1>Our Policy</h1>
                    <p className="lead">
                      These terms and conditions are effective as of 2017-09-29
                    </p>
                    <hr></hr>
                  </div>
                  <div className="col-12">
                    <h3 className="mb-4">Terms & Conditions</h3>
                    <p>
                      By downloading or using our app, websites or services
                      these terms will automatically apply to you – you should
                      make sure therefore that you read them carefully before
                      using. You’re not allowed to copy, or modify, any part of
                      the app / website / services, or our trademarks in any
                      way. You’re not allowed to attempt to extract the source
                      code of our app / website / services, and you also
                      shouldn’t try to translate the app into other languages,
                      or make derivative versions. The app itself, and all the
                      trade marks, copyright, database rights and other
                      intellectual property rights related to it, still belong
                      to TECHIMPACE.
                    </p>
                    <p>
                      You should register yourself as our client by signing a
                      CRF (customer registration form). You should read
                      carefully about our terms and conditions, payment terms
                      before signing CRF.
                    </p>
                    <p className="text-primary">
                      All the customers should pay GST on every invoice. Please
                      don't excuse to paying the GST
                    </p>
                    <p>
                      Our app / website / services does use third party services
                      that declare their own Terms and Conditions.
                    </p>
                    <h3 className="mb-4">Ownership</h3>
                    <p>
                      All the projects, websites, and services developed by
                      TECHIMPACE and used by our clients are the property of
                      TECHIMPACE by default. If any client wishes to have the
                      codebase, they should inform TECHIMPACE before the The
                      agreement may affect the pricing of the project.
                    </p>
                    <p>
                      If the Ownership of the project was not mentioned by the
                      client before the agreement, TECHIMPACE has the full
                      rights to decide what to do. In such case, TECHIMPACE may
                      or may not transfer the codebase to the client to keep our
                      written code safe and secret.
                    </p>
                    <h3 className="mb-4">NDA - Non disclosure agreement</h3>
                    <p>
                      We also support NDA for various projects. NDA comes under
                      complete trade secrect. Our clients can sign an NDA with
                      us by paying standard fees. NDA depends upon project types
                      and categories.
                    </p>
                    <h3 className="mb-4">Privacy Policy</h3>
                    <p>
                      This page is used to inform visitors regarding our
                      policies with the collection, use, and disclosure of
                      Personal Information if anyone decided to use our Service.
                    </p>
                    <p>
                      If you choose to use our Service, then you agree to the
                      collection and use of information in relation to this
                      policy. The Personal Information that we collect is used
                      for providing and improving the Service. We will not use
                      or share your information with anyone except as described
                      in this Privacy Policy.
                    </p>
                    <p>
                      The terms used in this Privacy Policy have the same
                      meanings as in our Terms and Conditions, which is
                      accessible at Suruchi unless otherwise defined in this
                      Privacy Policy.
                    </p>
                    <p>
                      <b>Information Collection and Use</b>
                    </p>
                    <p>
                      For a better experience, while using our Service, we may
                      require you to provide us with certain personally
                      identifiable information, including but not limited to
                      Name, Email, Mobile Number, Location. The information that
                      we request will be retained by us and used as described in
                      this privacy policy.
                    </p>
                    <p>
                      The app does use third party services that may collect
                      information used to identify you.
                    </p>
                    <p>
                      Link to privacy policy of third party service providers
                      used by the app
                    </p>
                    <ul>
                      <li>
                        <a
                          href="https://firebase.google.com/policies/analytics"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Google Analytics for Firebase
                        </a>
                      </li>
                    </ul>
                    <h3 className="mb-4">Refund Policy</h3>
                    <p>
                      You can cancel any order or project according to the
                      agreement, in such case You should pay 100% cancellation
                      fee.
                    </p>
                    <p className="text-danger">
                      <b>No Refund will be entertained after the payment.</b>
                    </p>
                    <h3 className="mb-4">Changes to This Policy</h3>
                    <p>
                      We may update our Terms and Conditions from time to time.
                      Thus, you are advised to review this page periodically for
                      any changes. We will notify you of any changes by posting
                      the new Terms and Conditions on this page.
                    </p>
                    <h3 className="mb-4">Contact Us</h3>
                    <p>
                      If you have any questions or suggestions about our Terms
                      and Conditions, do not hesitate to contact us at
                      <b> care@techimpace.com</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      )}
    </ThemeContext.Consumer>
  )
}
export default PolicyPage
